.table {
  display: block;
  border-spacing: 0;;
}

.thead_wrapper {
  border: 1px solid #888;
  border-bottom: none;
  background: #efefef;
  border-bottom: 1px solid #888;
}
.thead {
  overflow-x: hidden;
}

.tbody {
  border: 1px solid #888;
  border-top: none;
}

.th {
  font-weight: bold;
}
.th, .td {
  margin: 0;
  padding: 0.5rem;
  /* border-bottom: 1px solid #888; */
  /* border-right: 1px solid #888; */
}
.td {
  border-bottom: 1px solid #888;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.tabs li.active {
  background: #4c87c3;
  color: #fff;
  margin-bottom: -1px;
} 

.tabs li {
  background: #eee;
  cursor: pointer;
  display: block;
  margin: 0 2px;
  padding: 10px 20px;
}

.contentWrapper {
  border-top: 2px solid #ddd;
}

.contentWrapper {
  display: flex; 
}

.contentWrapper p {
  background: #ccc;
}

.defWrapper {
  width: 30rem;
}

.fileWrapper {
  margin-left: 1rem;
}

.buttons {
  margin-top: 40px;
}

.buttons button {
  width: 100%;
}
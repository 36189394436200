.exif_table {
  border-collapse: collapse;
}

.exif_table th {
  background: #ccc;
}

.exif_table th, .exif_table td {
  border: 1px solid #aaa;
  padding: 0.4rem;
}

/** ドラッグエリア */
.dragzone {
  padding: 2rem;
  background: #eee;
  border: 5px dashed #999;
  color: #555;
  text-align: center;
}
  /** ドラッグ中の状態 */
  .dragging {
    background: #ddd;
  }
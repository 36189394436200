.container {
  display: grid;
  gap: 0;
  grid-template-columns: minmax(0, 1fr) 300px;
}

.preview {
  background: #222;
  /* height: 400px; */
}
  .preview .title {
    background: #ccc;
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
  .preview .image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .preview .image img {
    max-width: 90%;
    max-height: 390px;
  }

.property {
  background: #eeeeee;
}
  .property .title {
    background: #ccc;
    color: #fff;
    background-image: linear-gradient(135deg, #415065 0%, #c3cfe2 100%);
  }
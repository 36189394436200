.rootContainer {
  height: 100%;
  background: #F3F6FC;
  border-right: 1px solid #eee;
  z-index: 1000;
}

.rootContainer .menuList li {
  margin-right: 20px;
  margin-left: 12px;
}
.rootContainer .menuList a {
  padding: 16px 12px;
  white-space: nowrap;
}
  .rootContainer .menuList a:hover {
    /* background-color: #CADAFD; */
    background-color: #e8e5e5;
    border-radius: 30px 30px 30px 30px;
  }

@media screen and (max-width: 1024px) {
  .rootContainer {
    width: 40px;
    position: absolute;
    transition: width 200ms ease; 
  }
  .rootContainer.open {
    width: 20rem;
    max-width: 95%;
    box-shadow: 5px 0px 8px -7px rgba(0,0,0,0.6);
  }
  .rootContainer:not(.open) .menuList li {
    margin-right: 0px;
    margin-left: 0px;
  }
    .rootContainer:not(.open) .menuList a {
      padding: 16px 4px;
    }
    .rootContainer:not(.open) .menuList a:hover {
      /* background-color: #CADAFD; */
      background-color: #e8e5e5;
      border-radius: 10px 10px 10px 10px;
    }
    .rootContainer:not(.open) .menuList a .text {
      display: none;
    }
    .rootContainer.animating .menuList a .text {
      display: none;
    }
}
.duplicate_guide {
  background-color: rgb(245, 194, 186);
  height: 1rem;
  width: 1rem;
  display: inline-block;
}

.duplicate {
  background-color: rgb(245, 194, 186);
}
.no_duplicate {
  background-color: rgb(189, 242, 247);
}
/** ドラッグエリア */
.dragzone {
  padding: 1rem;
  background: #eee;
  border: 5px dashed #999;
  color: #555;
  text-align: center;
}
  /** ドラッグ中の状態 */
  .dragging {
    background: #ddd;
  }

/* ファイル一覧 */
.fileTable {
  border-collapse: collapse;
  width: 100%;
}
  .fileTable th {
    background: #eee;
    border-bottom: 1px solid #555;
  }
  .fileTable th, .fileTable td {
    border-bottom: 1px solid #555;
    padding: 0.5rem;
  }
  .fileTable tbody tr td:nth-child(1) {
    width: 1.5rem;
  }
  .fileTable tbody tr td:nth-child(3) {
    text-align: right;
    padding-right: 0.5rem;
  }
  .fileTable tr.notarget {
    background: #999;
  }


/** 結合実行ボタン **/
.btnExecute {
  margin-top: 1rem;
  min-width: 200px;
  width: 80%;
}
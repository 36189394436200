.header {
  box-shadow: 0px 2px 13px -8px rgba(0,0,0,0.6); 
  /* border-bottom: 1px solid #eee; */
  /* margin-bottom: 1px; */
}
.header nav {
  background: #F3F6FC;
}
  .headerTitle {
    font-size: 20px;
    font-weight: bold;
    color: #555;
  }
  .menuOpener {
    display: none;
    margin-left: 18px;
  }
    .menuOpener button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 8px;
      appearance: none;
    }
    .menuOpener button:hover {
      background: rgb(217, 217, 217);
      border-radius: 50%;
    }

@media screen and (max-width: 1024px) {
  .menuOpener {
    display: block;
  }
}
.grid {
  display: grid;
  gap: 0;
  grid-template-columns: auto minmax(0, 1fr);
  height: 100%;
  margin: 0 0 0 0;
}

@media screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: 40px minmax(0, 1fr);
  }
}

.side {
  
}

.main {
}

h1.pageTitle {
  margin: 0;
  font-size: 20px;
  color: #fff;
  background: rgb(46, 155, 188);
  
}

.routeContent {
  margin: 10px;
}
.frameContainer {
  margin-top: 8px;
}

.frames {
  background: #eee;
  padding: 16px;
  display: flex;
  overflow-x: scroll;
  border: 1px solid #333;
}
  .frames .scrollContainer {
    display: flex;
    gap: 12px;
    position: relative;
  }

.frameBar {
  display: flex;
  padding: 4px 0;
}
  .frameBar .buttonGroup:nth-child(n + 2) {
    margin-left: 1rem;
  }

.imageWrapper {
  flex-shrink: 0;
  cursor:default;
  box-sizing: border-box;
}
  .imageWrapper.selected {
    border: 1px solid rgb(79, 120, 208);
    box-shadow: 0px 0px 7px 2px rgba(39, 64, 205, 0.44);
  }
  .imageWrapper img {
    height: 100px;
    max-width: 400px;
    object-fit: contain; 
  }
  .imageWrapper .noimg {
    width: 100px;
    height: 100px;
    background: #333;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .imageWrapper.selected img {
      padding: 8px;
    }
  .imageWrapper .addImage{
    width: 100px;
    height: 100px;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }